
.top-nav-menu{
  height:4em !important;
  margin:0em !important;
  background-color: #03041A !important;
  color: #FFFFFF !important;
}

.top-nav-menu-item{
  margin:0em !important;
  text-align: left !important;
  font-size: 16px;
  color: #FFFFFF !important;
}

.top-nav-container{
  margin:0em !important;

}

.top-nav-menu-item:hover{

  font-weight: 600 !important;
}

.top-nav-menu-button{
  margin-left: 0.5em !important;

}
