#tsparticles {

    width: 100%;
    height: 100%;

}

.particles-container{
  position: relative;

}

.background-container{
  background-color: #03041A !important;
  height:100%;
  width:100%;

}

.scroll-segment-top{
  height:100%;
  width:100%;
  background-color: #03041A !important;
  background-image: url("../../media/img/circuit1.jpg") !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  opacity: 0.6;
}

.scroll-segment-middle{
  height:100%;
  width:100%;
  background-color: #FFFFFF !important;
  background-image: url("../../media/img/chip.jpg") !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  opacity: 0.4;
}
