@import "../../styles/_common.css";


.action-segment-dark {
    height: 180px;
    width: 100%;
    margin: 0px 0px;
    padding: 10px 10px;
    display:block;
    background-color: #050833 !important;
    border-style: solid !important;
    border-color: #000000 !important;
    border-radius: 5px !important;
    .h2 {
    color:  #FFFFFF !important;
    }
    .ui.subheader {
    color:  #FFFFFF !important;
    }
}

.action-segment-light {
    height: 180px;
    width: 100%;
    margin: 0px 0px;
    padding: 10px;
    -ms-flex: 1;  /* IE 10 */
    flex: 1;
    background-color: #FFFFFF !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: #000000 !important;
    border-radius: 10px !important;
}


.action-card {
border-style: solid;
}
