body {
  margin: 0;
  height: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  height: 100%;
  width: 100%;


}

.ui.menu .active.item {
    font-weight: 700 !important;
    color: #000000 !important;

    text-decoration: underline;
    text-decoration-color: #D5D4D2;
    text-decoration-thickness: 4px;
    text-underline-offset: 3px;
    margin: 1em 0em;
}
