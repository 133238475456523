

.segment-header {
  font-size: 3em !important;
  padding-top: 0em;
  padding-top: 0em;
  color: #FFFFFF !important;
  font-family: 'Urbanist' !important;
}
.segment-text {
  font-size: 1.5em !important;

  color: #FFFFFF !important;
  font-family: 'Urbanist' !important;
}

.segment-list {
  margin-top: 1.0em ;

}

.main-segment {
  opacity: 0.8;
  padding: 0em !important;
  border-radius: 20px !important;

}

@media screen and (min-width: 0px) {
  .main-segment {
    width: 90% !important;
  }

}

@media screen and (min-width: 768px) {
  .main-segment {
    width: 80% !important;
  }


}

.services-card{
  background-color: transparent !important;
  margin: 1em 0em !important;
  text-align: center !important;
}

.services-card-header{
  color: #FFFFFF !important;
  opacity: 1 !important;
  font-size: 1.75em !important;
  font-family: 'Urbanist' !important;
  font-weight: 600 !important;
  text-align: left !important;
}

.services-card-text{
  color: #FFFFFF !important;
  opacity: 1 !important;
  font-size: 1.15em;
  font-weight: 200 !important;
  font-family: 'Urbanist' !important;
    text-align: left !important;

}
