$lightGreen: #3cbfc8;
$lightBlue: #01a8fe;
$skyBlue: #2bd6e2;
$darkBlue: #050833;
$backgroundGray: #f8f8f8;
$fontGray: #4a4a4a;
$darkGray: #4a4a4a;

$boxShadow: 0 2px 20px 0 rgba(185, 188, 210, 0.2);

/*FONTS*/
@import url('https://fonts.googleapis.com/css?family=Satisfy|Nunito|Raleway|Urbanist|Lato|Open+Sans');

/* Font Variables*/
$satisfy: 'Satisfy', cursive;
$raleway: 'Raleway', sans-serif;
$lato: 'Lato', sans-serif;
$open-sans: 'Open Sans', sans-serif;
$nunito: 'Nunito', sans-serif;
