@import "../../styles/_common.css";


.card-segment {
    height: 360px;
    width: 100%;
    margin: 0px 0px;
    padding: 0px 0px;
}

.card-container {
    padding: 2em 0em;
    height: 300px;
    width: 100%;

}

.card-stat {
    margin: 0em;
    font-size: 18px;
    font-weight:600;
}

.card-stat-label {
    margin: 0em;
    color:  #4a4a4a;
}

.stat-card {
  display: block !important;
}
