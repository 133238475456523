#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100% !important;
  height: 500px !important;
}

.particles-container{
  width: 100% !important;
  position:relative !important;
  display:flex !important;
  background-color: #03041A;
}

.header-container{
  color: #FFFFFF !important;
  background-color: #03041A !important;
  text-align: left !important;
  margin:0em !important;
}

.header-text {
  font-family: "Nunito" !important;
  color: #FFFFFF !important;
}

.home-segment{
  padding: 3em 1em !important;
  min-height: 500px !important;
  margin: 0em !important;
  background-color: #f8f8f8 !important;
  display: flex !important;
  align-items: center !important;
}

.home-segment-middle{
  padding: 1em 0em !important;
  margin: 0em !important;
  min-height: 600px !important;
  background-image: url("../../media/img/chip.jpg") !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  display: flex !important;
  align-items: center !important;
}
