@import "../../styles/_common.css";

.chart-container {
    height: 300px;
    width: 100%;

}

.altchart-container {
    height: 300px;
    width: 100%;
    padding: 3em;
}

.chart-segment {
    height: 360px;
    width: 100%;
    margin: 0px 0px;
    padding: 2em;

}
