

.dashboard-segment {
  padding: 1em 6em !important;
}

.dashboard-menu {
  border-style: None !important;
  box-shadow: none !important;
}
