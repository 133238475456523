

.main-header {
  color: #FFFFFF !important;
  margin: 0em !important;
  font-family: 'Bebas Neue', cursive !important;
}
.sub-header {
  color: #FFFFFF !important;
  margin: 0em !important;
  font-family: 'Montserrat', sans-serif !important;
}
.main-logo {
  max-width: 2em;
}

@media screen and (min-width: 0px) {
  .main-header {
    font-size: 2em !important;
  }
  .sub-header {
    font-size: 1em !important;
  }

}

@media screen and (min-width: 768px) {
  .main-header {
    font-size: 5em !important;
  }
  .sub-header {
    font-size: 2em !important;
  }


}
